<template>
    <div>
        <vendorHeader />
        <div class="m-2 pa-2">
            <tabs-comp :tabs="tabs" />
            <v-row>
                <v-col cols="12">
                    <v-simple-table striped hover style="width:100%;">
                        <thead>
                            <tr>
                                <th class="text-center">تاريخ الارسالية</th>
                                <th class="text-center">عدد المنتجات</th>
                                <th class="text-center">الاجمالي</th>
                                <th class="text-center">الحالة</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in myrequests" :key="index">
                                <td class="text-center">{{item.reqdate}}</td>
                                <td class="text-center">{{item.qtty}}</td>
                                <td class="text-center">{{item.total}}</td>
                                <td class="text-center">{{item.status}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        <Footer />
    </div>
</template>

<script>
import vendorHeader from '@/components/vendor-header.vue'
import Footer from '@/components/Footer.vue'
import TabsComp from '@/components/tabsComp.vue'
export default({
    components: {vendorHeader,Footer,TabsComp},
    data() {
        return {
            myrequests:[
              {reqdate: '2022-02-01',qtty:'10',edate:'2022-02-01',status: 'تم الاستلام',total:250},
              {reqdate: '2022-02-15',qtty:'15',edate:'2022-02-20',status: 'تم الاستلام',total:500},
              {reqdate: '2022-02-20',qtty:'200',edate:'2022-02-28',status: 'تم الاستلام',total:1000},
              {reqdate: '2022-03-02',qtty:'35',edate:'2022-03-02',status: 'بالانتظار',total:250.2},
            ]
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        }, 
        tabs: function(){
            return [
                {
                    index:0,name:this.lang.sales,href:'/vendors/vendor-index/',class:'mytab3',
                },
                {
                    index:2,name:this.lang.payment_requests,href:'/vendors/payment-requests/',class:'mytab3',
                },
                {
                    index:3,name:this.lang.requests,href:'/vendors/requests/',class:'mytab',
                },
                {
                    index:3,name:this.lang.products,href:'/vendors/products/',class:'mytab3',
                },
            ]
        }
    }
})
</script>
<style>
.v-data-table-header__icon{
  display:none !important;
}
.outsearch{
  color:green;
  border:1px solid #ccc;
}
thead th, tfoot th, .tfoot th{
  background: #c3d9ff !important;
  color:#000 !important;
  border:1px solid #000 !important;
  font-size:12px;
  white-space: nowrap;
}
thead th a , thead th i, thead th i a{
  color:#FFF !important;
  white-space: nowrap;
}
tbody td{
  font-size:12px;
  color:#000 !important;
  white-space: nowrap;
  border:1px solid #000;
}
.v-breadcrumbs{
  padding:12px !important;
}
.v-tab--active{
  background:#ccc;
  color:#FFF;
}
.v-tabs-slider-wrapper a{
  color:#FFF !important;
}
tfoot th{
  border:1px solid #000;
}
.close svg {
    width: 74px !important;
    height: 34px !important;
    color: #FFF;
    background: red;
}
header button.close {
    width: 24px !important;
    height: 24px !important;
}
input{
  box-shadow: none !important;
}
button:active,button:focus{
  box-shadow: none !important;
}
._mybtn{
  font-size:.8rem;
  border:0px solid #fff;
  width:100%;
}
._mybtn2{
  font-size:.8rem;
  background:rgb(15, 212, 230) !important;
  border:0px solid #000;
  width:100%;
}
._mybtn4{
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
._mybtn3{
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
._mybtn1{
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
td,th{
      height: 30px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px !important;
}
</style>